import { FC } from 'react';
import { OrderStatus } from 'ventas-digitales-v2-common';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import locale from '../utils/locale';
import {
  blue,
  common,
  cyan,
  deepOrange,
  green,
  grey,
  orange,
  red,
} from '@mui/material/colors';

export const STATUS_BG_COLORS: Record<OrderStatus, string> = {
  [OrderStatus.Deassigned]: grey[300],

  /** Mobile statuses */
  [OrderStatus.Pending]: blue[100],
  [OrderStatus.InProgress]: blue[200],
  [OrderStatus.Signed]: blue[300],
  [OrderStatus.Completed]: blue[400],

  /** Transition statuses */
  [OrderStatus.PendingGIS]: cyan[100],
  [OrderStatus.PendingGIS2]: cyan[200],
  [OrderStatus.PendingCertificate]: cyan[300],
  [OrderStatus.PendingCreation]: cyan[400],

  /** Action-required statuses */
  [OrderStatus.RejectedGIS]: orange[200],
  [OrderStatus.PendingContractor]: orange[300],
  [OrderStatus.PendingValidation]: orange[400],
  [OrderStatus.CreationError]: orange[600],

  /** Final/error statuses */
  [OrderStatus.Closed]: deepOrange[400],
  [OrderStatus.ClosedGIS]: deepOrange[500],
  [OrderStatus.RejectedContractor]: deepOrange[600],
  [OrderStatus.RejectedValidation]: deepOrange[700],
  [OrderStatus.Created]: green[500],
  [OrderStatus.ReadyToCreate]: green[500],
  [OrderStatus.Failed]: red[900],
};

export const STATUS_COLORS: Record<OrderStatus, string> = {
  [OrderStatus.Deassigned]: common.black,

  /** Mobile statuses */
  [OrderStatus.Pending]: common.black,
  [OrderStatus.InProgress]: common.black,
  [OrderStatus.Signed]: common.white,
  [OrderStatus.Completed]: common.white,

  /** Transition statuses */
  [OrderStatus.PendingGIS]: common.black,
  [OrderStatus.PendingGIS2]: common.black,
  [OrderStatus.PendingCertificate]: common.black,
  [OrderStatus.PendingCreation]: common.black,

  /** Action-required statuses */
  [OrderStatus.RejectedGIS]: common.black,
  [OrderStatus.PendingContractor]: common.black,
  [OrderStatus.PendingValidation]: common.black,
  [OrderStatus.CreationError]: common.black,

  /** Error statuses */
  [OrderStatus.Closed]: common.black,
  [OrderStatus.ClosedGIS]: common.white,
  [OrderStatus.RejectedContractor]: common.white,
  [OrderStatus.RejectedValidation]: common.white,
  [OrderStatus.Failed]: common.white,

  [OrderStatus.Created]: common.white,
  [OrderStatus.ReadyToCreate]: common.white,
};

type OrderStatusChipProps = {
  status: OrderStatus;
  size?: 'small' | 'medium';
};

const OrderStatusChip: FC<OrderStatusChipProps> = ({
  status,
  size = 'small',
}) => {
  const label = locale.orderStatusEnum[status] ?? status;
  const backgroundColor = STATUS_BG_COLORS[status];
  const color = STATUS_COLORS[status];

  return (
    <Chip
      sx={{ backgroundColor, color }}
      size={size}
      label={
        <Typography variant="overline" fontWeight="bold">
          {label}
        </Typography>
      }
    />
  );
};

export default OrderStatusChip;
